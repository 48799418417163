import React, { useEffect } from 'react'

import Layout from '../components/Layout/Layout'
import '../styles/methodology.scss'
import { Helmet } from 'react-helmet'
import Collapsible from 'react-collapsible'

export default () => {
  // Initialize Hubspot Form
  useEffect(() => {
    const handleHubspotScript = () => {
      // Instantly remove event listener
      window.removeEventListener('scroll', handleHubspotScript)

      // Create hubspot script
      setTimeout(() => {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/v2.js'
        script.defer = true
        document.body.appendChild(script)

        // Wait for script to load, then add form
        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: '5666503',
              formId: 'd9fad6d2-f18e-4375-9632-197d411a786c',
              target: '#hubspot_form',
            })
          }
        })
      }, 1000)
    }

    // Add event listener
    window.addEventListener('scroll', handleHubspotScript)

    return () => {
      window.removeEventListener('scroll', handleHubspotScript)
    }
  }, [])

  return (
    <Layout title="College Pulse - Be Counted">
      <main>
        <Helmet>
          <title>College Pulse | Be Counted</title>
          <meta property="og:image" content="/cp-website-min.jpg" />
        </Helmet>
        <section className="has-background-primary has-padding-6 is-centered methodology-title">
          <h1 className="title has-text-white has-text-weight-bold has-margin-top-4 has-margin-bottom-3">
            INSTITUTIONS: BE COUNTED
          </h1>
        </section>

        <section
          style={{ backgroundColor: 'rgb(249,249,249)', paddingTop: '3rem', paddingBottom: '3rem' }}
          className="content container has-margin-bottom-6 blogpost-container"
        >
          <div>
            <h3>Who is College Pulse</h3>
            <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
            <p>
              College Pulse stands at the forefront of academic insight, specializing in online
              surveys dedicated to better understanding current university students’ mindsets,
              inclinations, and actions. Through our unique American College Student Panel, we’ve
              surveyed more than 675,000 college students and alumni from over 1,500 institutions,
              spanning both two- and four-year programs in every state.
            </p>
            <p>
              Our insights fuel strategies and decisions in various sectors, including higher
              education, politics, religion, consumer goods, and more. We provide businesses with
              tools to pinpoint excellent employment prospects, guide political leaders in shaping
              student-centric policies, and aid aspiring students in finding their perfect academic
              home.
            </p>
            <p>
              The following are examples of reports that could specifically mention your
              institution.
            </p>
            <div className="columns has-padding-0 has-margin-0">
              <div className="column is-half has-padding-0 has-margin-0">
                <a
                  href="https://www.wsj.com/rankings/college-rankings/best-colleges-2024"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/img/wsj-share.jpg" alt="College Pulse Logo" />

                  <p style={{ fontWeight: '900' }}>The 2024 WSJ / College Pulse Rankings</p>
                </a>
              </div>
              <div className="column is-half has-padding-0 has-margin-0">
                <a href="https://speech.collegepulse.com" target="_blank" rel="noreferrer">
                  <img src="/img/speech-share.png" alt="Freedom of Speech Dashboard" />

                  <p style={{ fontWeight: '900' }}>The 2024 Freedom of Speech Dashboard</p>
                </a>
              </div>
            </div>
          </div>
          <div className="has-margin-top-5">
            <h3>We want you to be included!</h3>
            <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
            <p>
              For the integrity of projects like the 2024 WSJ/College Pulse Rankings and the 2024
              Freedom of Speech Dashboard, we require responses from at least 100 students per
              institution. While College Pulse is known for immediate compensation for surveys,
              email filters occasionally misinterpret our outreach as spam, which can limit student
              participation. Your understanding and collaboration are vital to overcoming these
              challenges and achieving robust, inclusive research.
            </p>
          </div>
          <div className="has-margin-top-5">
            <h3>What you can do to avoid disqualification</h3>
            <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
            <p>
              Let your IT department, or any other responsible parties, know that you want our
              emails to not be marked as spam. You can do that by sending them to this page, or by
              providing them the following information.
            </p>
            <p>
              To ensure you’re not unintentionally blocking College Pulse, the following domains,
              ip’s, and addresses need to be whitelisted from spam filtering and/or firewalls.
            </p>
            <p>
              Domains:
              <ul>
                <li>collegepulse.com (Our root domain and landing page)</li>
                <li>app.collegepulse.com (Our legacy application url)</li>
                <li>surveys.collegepulse.com (Our modern application url - WIP)</li>
                <li>students.collegepulse.com (Primary Email Domain)</li>
                <li>pulsesurvey.com (Secondary Email Domain)</li>
              </ul>
            </p>
            <p>
              IP Ranges:
              <ul>
                <li>50.31.205.0/24 (50.31.205.1 - 255) (SMTP Outbound - Postmark)</li>
              </ul>
            </p>
            <p>
              Email Addresses:
              <ul>
                <li>mary@students.collegepulse.com (Outbound, sending surveys)</li>
                <li>mary@collegepulse.com (Transactional / manual outreach)</li>
                <li>mwall@collegepulse.com (Reply handling)</li>
                <li>jaxon.smith@students.collegepulse.com (Outbound, sending surveys)</li>
                <li>jaxon.smith@collegepulse.com (Transactional / manual outreach)</li>
                <li>no-reply@pulsesurvey.com (Transactional / backup outbound)</li>
              </ul>
            </p>
          </div>
          <div className="has-margin-top-5">
            <h3>Get Notified!</h3>
            <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
            <p>
              Want to know which surveys are headed to your institution? Complete the form below for
              notifications. Plus, we’ll provide a direct link for you to share with your students,
              ensuring their participation in the surveys most relevant to you.
            </p>
            <div
              style={{
                backgroundColor: 'white',
                width: '100%',
                padding: '2rem',
                paddingBottom: '1rem',
                borderRadius: '0.75rem',
              }}
              id="hubspot_form"
            ></div>
          </div>
          <div className="has-margin-top-5">
            <h3>Frequently Asked Questions</h3>
            <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
            <Collapsible trigger="Why does College Pulse want to send emails to students?">
              <p>
                College Pulse reaches out to students via email primarily to invite them to
                participate in our surveys. By doing so, we aim to gather genuine and diverse
                opinions from the college demographic. Not only does this help in producing accurate
                and reliable data, but it also provides students with an opportunity to voice their
                opinions and get compensated for their time and insights. We value the feedback of
                college students, and emails are an efficient way to engage with them directly.
              </p>
            </Collapsible>

            <Collapsible trigger="Why is College Pulse struggling to deliver emails?">
              <p>
                Delivering emails efficiently, especially in large volumes, presents challenges.
                Sometimes, email providers might misinterpret our outreach as promotional or spam,
                causing emails to be filtered out or placed in a different folder. We’re
                continuously refining our email delivery techniques and work closely with email
                providers to ensure our communications reach students without hindrance. We
                appreciate your support in taking steps to mitigate this issue.
              </p>
            </Collapsible>

            <Collapsible trigger="How does a student benefit from participating in College Pulse surveys?">
              <p>
                When students participate in College Pulse surveys, they receive compensation for
                their valuable time and insights. Beyond this, they have the opportunity to voice
                their opinions on pertinent topics, ensuring their perspectives are represented in
                broader discussions. Many surveys also enlighten students on current events and
                trends, fostering reflection and increased awareness.
              </p>
            </Collapsible>

            <Collapsible trigger="How does an institution benefit from participating in College Pulse surveys?">
              <p>
                The advantages of participating in College Pulse surveys are twofold. Firstly, it
                provides institutions access to deep insights about their student body, enabling
                data-driven decisions that can enhance the academic experience. These insights
                illuminate strengths, growth points, and emerging trends. Secondly, there’s the
                prestige factor: institutions have the opportunity to be featured in
                highly-publicized research, making lasting impressions on prospective students and
                the wider academic community.
              </p>
            </Collapsible>

            <Collapsible trigger="How do companies intending to rank an institution use the data?">
              <p>
                Companies aiming to rank institutions utilize College Pulse survey data to capture a
                holistic view of student experiences and satisfaction. The data informs rankings on
                criteria like academic quality, campus life, and student well-being. By
                incorporating direct student feedback, these rankings offer a more authentic
                depiction of each institution, balancing traditional metrics with real student
                perspectives. The published reports offer a comprehensive approach that aids
                prospective students and educators in discerning an institution's strengths and
                growth areas.
              </p>
            </Collapsible>

            <Collapsible trigger="How do companies not intending to rank an institution use the data?">
              <p>
                Companies not focused on ranking institutions use College Pulse survey data to
                understand broader trends, behaviors, and sentiments among the U.S. college
                population. The data aids in guiding employment prospecting, crafting marketing
                strategies, and informing policy or advocacy efforts. Essentially, the insights
                drawn from the surveys offer companies a clear lens into the preferences,
                challenges, and aspirations of college students, enabling more informed and
                effective decision-making in their respective sectors.
              </p>
            </Collapsible>
            <Collapsible trigger="How can we be sure the data collected is protected?">
              <p>
                Ensuring the protection and privacy of the data we collect is paramount to College
                Pulse. Data shared with clients is always anonymized, ensuring that no personal
                identifying information is attached. Furthermore, the data is presented in an
                aggregated format. While companies can view breakdowns of responses based on
                demographics, they don't have access to individual-level data. This ensures
                individual responses remain confidential while still providing companies with the
                overarching trends and insights they seek.
              </p>
            </Collapsible>
          </div>
        </section>
      </main>
    </Layout>
  )
}
